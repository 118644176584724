<ng-container *ngIf="vm$ | async">
    <div [ngClass]="allFormVisible() ? 'o-page' : 'o-small-page'">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-alert
                        *ngIf="isSuccess"
                        content="Request has been sent successfully."
                        [additionalClass]="'primary'"
                    ></medpace-alert>
                    <medpace-alert
                        *ngIf="!isGuidValid"
                        content="There is no invitation in the system."
                        [additionalClass]="'warning'"
                    ></medpace-alert>
                    <medpace-card *ngIf="!isSuccess && !isLoading && isGuidValid">
                        <medpace-card-header>
                            <div class="c-card__ribbon">
                                <div class="c-card__item c-card__item--left mb-2">
                                    <h3 *ngIf="isAuth">Access request</h3>
                                    <h3 *ngIf="!isAuth">Register account</h3>
                                </div>
                            </div>
                        </medpace-card-header>
                        <medpace-card-content>
                            <div class="c-form">
                                <div class="o-grid">
                                    <div [ngClass]="allFormVisible() ? 'o-grid__row' : 'o-grid_col'">
                                        <div
                                            class="o-grid__item"
                                            *ngIf="
                                                invitation?.studiesProtocol && invitation?.studiesProtocol.length > 0
                                            "
                                        >
                                            <div class="c-info__block">
                                                <h5>Affected Studies</h5>
                                                <div *ngFor="let studyProtocol of invitation?.studiesProtocol">
                                                    <span class="wrap">{{ studyProtocol }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="o-grid__item"
                                            *ngIf="invitation?.sitesName && invitation?.sitesName.length > 0"
                                        >
                                            <div class="c-info__block">
                                                <h5>Affected Sites</h5>
                                                <div *ngFor="let siteName of invitation?.sitesName">
                                                    <span>{{ siteName }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="o-grid__item">
                                            <div class="c-info__block">
                                                <h5>Access Type</h5>
                                                <span
                                                    >{{
                                                        invitation?.isPrimary !== null
                                                            ? invitation?.isPrimary
                                                                ? 'Primary'
                                                                : 'Supporting'
                                                            : ''
                                                    }}
                                                    {{ invitation?.accessGroupName }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="o-grid__row" *ngIf="allFormVisible()">
                                        <div class="o-grid__field">
                                            <div class="c-info__block">
                                                <mds-text-field
                                                    id="emailAddress"
                                                    [formGrp]="accessFormGroup"
                                                    formCtrlName="emailAddressControl"
                                                    placeholder="Type here..."
                                                    floatLabel="always"
                                                    appearance="outline"
                                                    inputType="text"
                                                    label="Email Address"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="o-grid__row" *ngIf="allFormVisible()">
                                        <div class="o-grid__field half">
                                            <div class="c-info__block">
                                                <mds-text-field
                                                    id="firstName"
                                                    [formGrp]="accessFormGroup"
                                                    formCtrlName="firstNameControl"
                                                    placeholder="Type here..."
                                                    floatLabel="always"
                                                    appearance="outline"
                                                    inputType="text"
                                                    label="First Name"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="o-grid__row" *ngIf="allFormVisible()">
                                        <div class="o-grid__field half">
                                            <div class="c-info__block">
                                                <mds-text-field
                                                    id="lastName"
                                                    [formGrp]="accessFormGroup"
                                                    formCtrlName="lastNameControl"
                                                    placeholder="Type here..."
                                                    appearance="outline"
                                                    floatLabel="always"
                                                    inputType="text"
                                                    label="Last Name"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                        <div class="o-grid__field half">
                                            <div class="c-info__block">
                                                <mds-text-field
                                                    id="phoneNumber"
                                                    [formGrp]="accessFormGroup"
                                                    formCtrlName="phoneNumberControl"
                                                    placeholder="Type here..."
                                                    appearance="outline"
                                                    floatLabel="always"
                                                    inputType="text"
                                                    label="Phone number"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="o-grid__row" *ngIf="isFromNoRolePage">
                                        <div class="o-grid__field">
                                            <mds-dropdown
                                                [closeOnPageScroll]="false"
                                                [formGrp]="accessFormGroup"
                                                formCtrlName="userAccessGroupControl"
                                                id="userAccessGroupField"
                                                appearance="outline"
                                                floatLabel="always"
                                                placeholder="Select access level"
                                                label="Access Level"
                                                [options]="accessLevels"
                                                (selectionChange)="select($event)"
                                            ></mds-dropdown>
                                        </div>
                                    </div>
                                    <div class="o-grid__row" *ngIf="isFromNoRolePage">
                                        <div class="o-grid__item">
                                            <medpace-alert
                                                *ngIf="
                                                    this.accessFormGroup?.controls.userAccessGroupControl?.value === 2
                                                "
                                                content="Specify Study details."
                                                [additionalClass]="'primary'"
                                            ></medpace-alert>
                                        </div>
                                    </div>

                                    <div class="o-grid__row" *ngIf="allFormVisible() || isFromNoRolePage">
                                        <div class="o-grid__field">
                                            <div class="c-info__block">
                                                <mds-textarea
                                                    [formGrp]="accessFormGroup"
                                                    formCtrlName="justificationControl"
                                                    id="justificationField"
                                                    [cdkAutosizeMinRows]="8"
                                                    [cdkAutosizeMaxRows]="8"
                                                    label="Justification"
                                                    appearance="outline"
                                                    [autoSize]="true"
                                                    floatLabel="always"
                                                    placeholder="Type here..."
                                                >
                                                </mds-textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div [ngClass]="allFormVisible() ? 'flex' : ''">
                                        <mds-button
                                            width="100%"
                                            label="Send"
                                            [variant]="'primary'"
                                            [id]="'sendBtn'"
                                            (clickEvent)="sendRequest()"
                                        ></mds-button>
                                    </div>
                                </div>
                            </div>
                        </medpace-card-content>
                    </medpace-card>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="o-grid__row" *ngIf="isLoading">
    <div class="o-grid__item">
        <medpace-loading-card></medpace-loading-card>
    </div>
</div>
