<mds-dialog [dialogTitle]="dialogTitle" style="max-width: 800px">
    <mds-dialog-content>
        <ng-container *ngIf="isEditMode" [ngTemplateOutlet]="Content_EditMode"></ng-container>
        <ng-container *ngIf="!isEditMode" [ngTemplateOutlet]="Content_ViewMode"></ng-container>
    </mds-dialog-content>
    <mds-dialog-actions id="medpace-create-modal-actions">
        <ng-container *ngIf="isEditMode" [ngTemplateOutlet]="Actions_EditMode"></ng-container>
        <ng-container *ngIf="!isEditMode" [ngTemplateOutlet]="Actions_ViewMode"></ng-container>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>

<ng-template #Content_EditMode let-context>
    <div class="o-grid" *ngIf="resultUser?.emailAddress && studies[0]; else spinner">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex start middle">
                    <span class="required-asterisk">*</span>
                    <span> indicates a required field </span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Email Address</h5>
                    <span>{{ resultUser?.emailAddress }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Status</h5>
                    <span>"{{ resultUser?.isActive ? 'Activated' : 'Disabled' }}"</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    [formGrp]="personalDataFormGroup"
                    [formCtrlName]="firstNameFieldName"
                    [id]="firstNameFieldName"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    [label]="firstNameFieldName"
                    width="100%"
                    matTooltipPosition="below"
                    aria-label="None"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    [formGrp]="personalDataFormGroup"
                    [formCtrlName]="lastNameFieldName"
                    [id]="lastNameFieldName"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    [label]="lastNameFieldName"
                    width="100%"
                    matTooltipPosition="below"
                    aria-label="None"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-text-field
                    [formGrp]="personalDataFormGroup"
                    [formCtrlName]="phoneNumberFieldName"
                    [id]="phoneNumberFieldName"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="tel"
                    [defaultErrorSpacing]="true"
                    floatLabel="always"
                    [label]="phoneNumberFieldName"
                    width="100%"
                ></mds-text-field>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__field">
                <mds-dropdown
                    floatLabel="always"
                    [closeOnPageScroll]="false"
                    [formGrp]="assignedAccessGroupFormGroup"
                    [formCtrlName]="userAccessGroupFieldName"
                    [id]="userAccessGroupFieldName"
                    appearance="outline"
                    [label]="userAccessGroupFieldLabel"
                    [placeholder]="userAccessPlaceholder"
                    [options]="accessGroupOptions$ | async"
                ></mds-dropdown>
            </div>
        </div>
        <ng-container *ngIf="isCRC || isAdmin">
            <div class="o-grid__row">
                <div class="o-grid__field chip-margin-top" [formGroup]="assignedStudyFormGroup">
                    <medpace-chip-list
                        id="assignedStudy"
                        autocompleteName="assignedStudy"
                        [filteredOptions]="filteredStudies$ | async"
                        placeholder="Please select study"
                        label="Assigned Study"
                        formCtrlName="studyControl"
                        [formGrp]="assignedStudyFormGroup"
                        [chiplist]="studyListChips$ | async"
                        (optionSelected)="selectedStudy($event)"
                        (optionRemoved)="removeStudy($event)"
                    ></medpace-chip-list>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isCRC">
            <div class="o-grid__row">
                <div class="o-grid__field" [formGroup]="assignedSiteFormGroup">
                    <medpace-chip-list
                        id="assignedSite"
                        [autocompleteName]="siteAutocompleteName"
                        [filteredOptions]="filteredSites$ | async"
                        placeholder="Please select sites"
                        label="Assigned Site"
                        [formCtrlName]="siteAutocompleteName"
                        [formGrp]="assignedSiteFormGroup"
                        [chiplist]="siteListChips"
                        (optionSelected)="selectedSite($event)"
                        (optionRemoved)="removeSite($event)"
                    ></medpace-chip-list>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #Content_ViewMode>
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Email Address</h5>
                    <span>{{ resultUser?.emailAddress }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Status</h5>
                    <span>"{{ resultUser?.isActive ? 'Activated' : 'Disabled' }}"</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>First Name</h5>
                    <span>{{ resultUser?.firstName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Last Name</h5>
                    <span>{{ resultUser?.lastName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Phone Number</h5>
                    <span>{{ resultUser?.phoneNumber ?? 'Undefined' }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Access Level</h5>
                    <span>{{ userAccessGroupName }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="resultUser?.studyIds && resultUser?.studyIds.length > 0">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Assigned Study</h5>
                    <div *ngFor="let study of selectedStudies">
                        <span class="wrap-anywhere">{{ study.protocol }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="resultUser?.siteIds && resultUser?.siteIds.length > 0">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Assigned Site</h5>
                    <div *ngFor="let site of selectedSites">
                        <span class="wrap-anywhere">{{ site?.info.name ?? 'Site name missing' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #Actions_EditMode>
    <div class="o-grid">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex start" *ngIf="resultUser?.hasLocalAuthentication">
                    <mds-button
                        [id]="'resetPasswordBtn'"
                        mat-button
                        color="warn"
                        (clickEvent)="resetPassword()"
                        label="Reset Password"
                        variant="outline"
                    ></mds-button>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="resultUser?.isActive">
        <mds-button
            [id]="'saveProfileBtn'"
            (clickEvent)="submitClick()"
            label="Save Profile"
            variant="primary"
        ></mds-button>
    </ng-container>
    <mds-button
        [id]="'close-btn'"
        label="Cancel"
        variant="outline"
        [disabled]="isCancelButtonDisabled()"
        (clickEvent)="cancelClick()"
    ></mds-button>
    <mds-button
        [id]="'userStateBtn'"
        variant="primary"
        color="warn"
        (clickEvent)="setUserState()"
        label="{{ resultUser?.isActive ? 'Disable' : 'Activate' }}"
    ></mds-button>
</ng-template>
<ng-template #Actions_ViewMode>
    <mds-button
        [id]="'editBtn'"
        variant="primary"
        color="primary"
        (clickEvent)="setEditMode()"
        label="Edit"
    ></mds-button>
    <mds-button [id]="'close-btn'" label="Cancel" variant="outline" (clickEvent)="cancelClick()"></mds-button>
</ng-template>
