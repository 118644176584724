import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdateUserDTO } from '@models/user';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { UserStateService } from '@services/state-management/user-state.service';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { mapAccessTypeName } from '../../../../../utility/accessGroup-helper';
import { MedpaceUserOperationAlertModalComponent } from '../../user-operation-alert-modal/user-operation-alert-modal.component';
import { MedpaceUserManagementModalComponent } from '../user-management-modal.component';

@Component({
    templateUrl: './update-user-management-modal.component.html',
    styleUrls: ['./update-user-management-modal.component.scss'],
})
export class UpdateUserManagementModalComponent extends MedpaceUserManagementModalComponent {
    updateUser: UpdateUserDTO;
    displayName: string;
    dbUserAccessGroupId: number;
    isEditMode: boolean = false;
    justification: string;
    userAccessGroupName: string;

    constructor(
        public dialogRef: MatDialogRef<MedpaceUserManagementModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            userEmail: string;
        },
        protected adminStudies: AdminStudyServices,
        protected accessGroupStateService: AccessGroupStateService,
        protected formBuilder: FormBuilder,
        protected userStateService: UserStateService,
        public dialog: MatDialog
    ) {
        super(dialogRef, adminStudies, accessGroupStateService, formBuilder, userStateService);
        this.userStateService.setUserByEmail(this.data.userEmail);
        this.userStateService
            .getUser()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((userDTO) => {
                    this.resultUser = userDTO;
                    this.dbUserAccessGroupId = userDTO.userAccessGroupId;
                    this.userAccessGroupName = mapAccessTypeName(userDTO.userAccessGroupId);
                    this.studyIdsForWhoseSitesUserIsPrimaryCRC =
                        this.userStateService.getStudyIdsForWhoseSitesUserIsPrimaryCRC();
                    this.siteIdsForWhoseSitesUserIsPrimaryCRC =
                        this.userStateService.getSitesIdsForWhoseSitesUserIsPrimaryCRC();
                    super._modalDataSetup();
                    this._setUserFields();
                    this.resultUser?.isActive ? this.parentFormGroup.enable() : this.parentFormGroup.disable();
                })
            )
            .subscribe();
    }

    submitClick(): void {
        super.submitClick(() => {
            this.resultUser.justification = this.justification;
            this.userStateService.updateUser();
        });
    }

    setEditMode(): void {
        let alertDialog: MatDialogRef<MedpaceUserOperationAlertModalComponent, string> = this.dialog.open(
            MedpaceUserOperationAlertModalComponent,
            {
                autoFocus: false,
                width: '400px',
                disableClose: true,
                data: true,
            }
        );

        alertDialog
            .afterClosed()
            .pipe(
                tap((justification) => {
                    if (justification) {
                        this.isEditMode = true;
                        this.justification = justification;
                    }
                })
            )
            .subscribe();
    }

    isCancelButtonDisabled(): boolean {
        return !this.dbUserAccessGroupId && this.resultUser.isActive;
    }

    setUserState(): void {
        if (
            this.studyIdsForWhoseSitesUserIsPrimaryCRC.length === 0 &&
            this.siteIdsForWhoseSitesUserIsPrimaryCRC.length === 0
        ) {
            this.userStateService.setUserStatus();
        }
    }

    resetPassword(): void {
        this.userStateService.resetUserPassword(this.resultUser.emailAddress);
    }
    private _setUserFields(): void {
        this.personalDataFormGroup.controls[this.firstNameFieldName].setValue(this.resultUser?.firstName);
        this.personalDataFormGroup.controls[this.lastNameFieldName].setValue(this.resultUser?.lastName);
        this.personalDataFormGroup.controls[this.phoneNumberFieldName].setValue(this.resultUser?.phoneNumber);
        this.personalDataFormGroup.controls[this.emailAddressFieldName].setValue(this.resultUser?.emailAddress);
        this.personalDataFormGroup.controls[this.emailAddressFieldName].disable();
        this.assignedAccessGroupFormGroup.controls[this.userAccessGroupFieldName].patchValue(
            this.resultUser?.userAccessGroupId ?? null
        );
    }
}
